import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ExplanationModal.css';

const ExplanationModal = ({ show, explanation, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>Объяснение</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">{explanation}</Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <Button variant="secondary" onClick={handleClose} className="custom-button">
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExplanationModal;
