// ServicesList.js
import React from 'react';
import { PiCheckFatLight, PiXThin } from "react-icons/pi";
import categoryNames from './constants/categoryNames'; 
import serviceNames from './constants/serviceNames';

const ServicesList = ({ product }) => {
  const getCategoryName = (categoryKey) => {
    return categoryNames[categoryKey] || categoryKey;
  };

  const getServiceName = (serviceKey) => {
    return serviceNames[serviceKey] || serviceKey;
  };

  return (
    <div className="services">
      <h2>Дополнительные услуги</h2>
      <div className="servicess">
        {product && Object.entries(product.services).map(([categoryKey, services]) => (
          <div key={categoryKey} className="category">
            <h3 className="category-title">{getCategoryName(categoryKey)}</h3>
            <ul className="services-list">
              {Object.entries(services).map(([serviceKey, available]) => {
                const serviceName = getServiceName(`${categoryKey}_${serviceKey}`);
                const displayedService = available 
                  ? (available > 1 ? `${serviceName} (+${available})` : serviceName) 
                  : serviceName;
                return (
                  <li key={serviceKey} className={`service ${available ? 'available' : 'unavailable'}`}>
                    <span className={`status-icon ${available ? 'available-icon' : 'unavailable-icon'}`}>
                      {available ? <PiCheckFatLight /> : <PiXThin />}
                    </span>{" "}
                    {/* Добавили пробел после иконки */}
                    <span className="service-name">{displayedService}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesList;
