// TabsContent.js
import React from 'react';
import { FaHome, FaRoad } from 'react-icons/fa';

const TabsContent = ({ activeTab, setActiveTab, product }) => (
  <div className="container">
    <div className="tab-buttons">
      <button
        className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
        onClick={() => setActiveTab(1)}
      >
        <FaHome className="icon-button"/> Аппартаменты
      </button>
      <button
        className={`tab-button ${activeTab === 2 ? 'active' : ''}`}
        onClick={() => setActiveTab(2)}
      >
        <FaRoad className="icon-button"/> Выезд
      </button>
    </div>
    <div className="values">
      <div className={`tab-content ${activeTab === 1 ? 'active' : ''}`}>
        <div className="row">
          <div className="column">
            <h3>Час</h3>
            <p>{product.price} ₽ &#8203;</p>
          </div>
          <div className="column">
            <h3>2 часа</h3>
            <p>{product.price2} ₽ &#8203;</p>
          </div>
          <div className="column">
            <h3>Ночь</h3>
            <p>{product.price3} ₽ &#8203;</p>
          </div>
        </div>
      </div>
      <div className={`tab-content ${activeTab === 2 ? 'active' : ''}`}>
        <div className="row">
          <div className="column">
            <h3>Час</h3>
            <p>{product.price_r} ₽ &#8203;</p>
          </div>
          <div className="column">
            <h3>2 часа</h3>
            <p>{product.price_r2} ₽ &#8203;</p>
          </div>
          <div className="column">
            <h3>Ночь</h3>
            <p>{product.price_r3} ₽ &#8203;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default TabsContent;
