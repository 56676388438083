import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';
import ProductDetail from './components/ProductDetail';
import NotFound from './components/NotFound';
import FormS from './components/FormS';
import TestForm from './components/TestForm';

moment.locale('ru');
function App() {
  return (
    <ConfigProvider locale={ruRU}>
      <Router>
        <div className="container mt-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/create" element={<FormS />} />
            <Route path="/testiktest3" element={<TestForm />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </ConfigProvider>
  );
}

export default App;