import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const postMultipartRequest = async (endpoint, formData) => {
    try {
        const response = await axios.post(`${BASE_URL}${endpoint}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Ошибка при отправке POST-запроса:', error);
        throw error;
    }
};
