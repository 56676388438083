import React, { useState, useEffect } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import { PiSmileySadLight, PiSmileySadFill, PiSmileyNervousThin, PiSmileyNervousFill, PiSmileyLight, PiSmileyFill } from 'react-icons/pi';
import { FcLikePlaceholder, FcLike } from 'react-icons/fc';
import './Rating.css'; // Импорт файла стилей
import { postMultipartRequest } from './Utils/api';
import ExplanationModal from './ExplanationModal';

const SmileRating = ({ initialValue, onRatingChange, modelId, tgId }) => {
  const [rating, setRating] = useState(initialValue);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [likes, setLikes] = useState({
    heart: { count: 0, liked: false, lastLikedAt: null },
    smile: { count: 0, liked: false, lastLikedAt: null },
    meh: { count: 0, liked: false, lastLikedAt: null },
    angry: { count: 0, liked: false, lastLikedAt: null },
  });

  const [canLike, setCanLike] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
    onRatingChange(newRating);
  };

  const [showExplanation, setShowExplanation] = useState(false);
  const [explanationText, setExplanationText] = useState('');
  const handleExplanationClick = (text) => {
    setExplanationText(text);
    setShowExplanation(true);
  };

  const handleCloseExplanation = () => {
    setShowExplanation(false);
  };

  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const response = await fetch(`${BASE_URL}getModelLikes?model_id=${modelId}&tg_id=${tgId}`);
        const data = await response.json();

        console.log('Данные о лайках:', data); // Выводим данные о лайках в консоль

        setLikes({
          heart: { count: data.likeCounts[3], liked: data.tg === '3', lastLikedAt: null },
          smile: { count: data.likeCounts[2], liked: data.tg === '2', lastLikedAt: null },
          meh: { count: data.likeCounts[1], liked: data.tg === '1', lastLikedAt: null },
          angry: { count: data.likeCounts[0], liked: data.tg === '0', lastLikedAt: null },
        });

        setCanLike(true); // Всегда позволяем ставить лайки после загрузки
      } catch (error) {
        console.error('Ошибка при загрузке лайков:', error);
      }
    };

    fetchLikes();
  }, [modelId]);

  const handleLike = async (icon) => {
    if (loading) return; // Если уже идет запрос, не отправляем новый

    setLoading(true); // Устанавливаем флаг загрузки
    try {
      let likeId;
      switch (icon) {
        case 'heart':
          likeId = 3;
          break;
        case 'smile':
          likeId = 2;
          break;
        case 'meh':
          likeId = 1;
          break;
        case 'angry':
          likeId = 0;
          break;
        default:
          break;
      }

      const currentLike = Object.keys(likes).find((key) => likes[key].liked);

      if (!canLike) {
        handleExplanationClick("Нельзя так часто ставить реакции, попробуйте изменить реакцию позже!");
        setLoading(false); // Сбрасываем флаг загрузки
        return;
      }

      if (currentLike) {
        // Проверяем, прошло ли уже 5 минут с последнего лайка
        if (likes[currentLike].lastLikedAt && Date.now() - likes[currentLike].lastLikedAt < 5 * 60 * 1000) {
          handleExplanationClick("Нельзя так часто ставить реакции, попробуйте изменить реакцию позже!");
          setLoading(false); // Сбрасываем флаг загрузки
          return;
        }

        // Удаляем лайк с предыдущего смайлика
        setLikes((prevLikes) => ({
          ...prevLikes,
          [currentLike]: { ...prevLikes[currentLike], count: prevLikes[currentLike].count - 1, liked: false },
        }));

        // Отправляем запрос на сервер для обновления лайка на новый смайлик
        await postMultipartRequest('updateModelLike', { model_id: modelId, like_id: likeId, tg_id: tgId });
      } else {
        // Отправляем запрос на сервер для сохранения лайка на новый смайлик
        await postMultipartRequest('saveLike', { model_id: modelId, like_id: likeId, tg_id: tgId });
      }

      // Устанавливаем лайк на новый смайлик и обновляем время последнего лайка
      setLikes((prevLikes) => ({
        ...prevLikes,
        [icon]: { ...prevLikes[icon], count: prevLikes[icon].count + 1, liked: true, lastLikedAt: Date.now() },
      }));

      setCanLike(false);
    } catch (error) {
      console.error('Ошибка при сохранении лайка:', error);
    } finally {
      setLoading(false); // Сбрасываем флаг загрузки в любом случае
    }
  };

  return (
    <div className="smile-rating-container">
      <div className="rating-icons">
        <div className="rating-icon-container">
          {likes.heart.liked ? (
            <FcLike
              className={`rating-icon heart ${rating === 3 ? 'selected' : ''} ${likes.heart.liked ? 'liked' : ''}`}
            />
          ) : (
            <FcLikePlaceholder
              className={`rating-icon heart ${rating === 3 ? 'selected' : ''} ${likes.heart.liked ? 'liked' : ''}`}
              onClick={() => {
                if (!likes.heart.lastLikedAt || Date.now() - likes.heart.lastLikedAt >= 5 * 60 * 1000) {
                  handleRatingChange(3);
                  handleLike('heart');
                } else {
                  console.log('Можно ставить лайк только раз в 5 минут');
                }
              }}
            />
          )}
          <span className="like-count">{likes.heart.count}</span>
        </div>
        <div className="rating-icon-container">

          {likes.smile.liked ? (
            <PiSmileyFill
              className={`rating-icon smile ${rating === 2 ? 'selected' : ''} ${likes.smile.liked ? 'liked' : ''}`}
            />
          ) : (
            <PiSmileyLight
              className={`rating-icon smile ${rating === 2 ? 'selected' : ''} ${likes.smile.liked ? 'liked' : ''}`}
              onClick={() => {
                if (!likes.smile.lastLikedAt || Date.now() - likes.smile.lastLikedAt >= 5 * 60 * 1000) {
                  handleRatingChange(2);
                  handleLike('smile');
                } else {
                  console.log('Можно ставить лайк только раз в 5 минут');
                }
              }}
            />
          )}
          <span className="like-count">{likes.smile.count}</span>
        </div>
        <div className="rating-icon-container">
          {likes.meh.liked ? (
            <PiSmileyNervousFill
              className={`rating-icon meh ${rating === 1 ? 'selected' : ''} ${likes.meh.liked ? 'liked' : ''}`}
            />
          ) : (
            <PiSmileyNervousThin
              className={`rating-icon meh ${rating === 1 ? 'selected' : ''} ${likes.meh.liked ? 'liked' : ''}`}
              onClick={() => {
                if (!likes.meh.lastLikedAt || Date.now() - likes.meh.lastLikedAt >= 5 * 60 * 1000) {
                  handleRatingChange(1);
                  handleLike('meh');
                } else {
                  console.log('Можно ставить лайк только раз в 5 минут');
                }
              }}
            />
          )}
          <span className="like-count">{likes.meh.count}</span>
        </div>
        <div className="rating-icon-container">
          {likes.angry.liked ? (
            <PiSmileySadFill
              className={`rating-icon angry ${rating === 0 ? 'selected' : ''} ${likes.angry.liked ? 'liked' : ''}`}
            />
          ) : (
            <PiSmileySadLight
              className={`rating-icon angry ${rating === 0 ? 'selected' : ''} ${likes.angry.liked ? 'liked' : ''}`}
              onClick={() => {
                if (!likes.angry.lastLikedAt || Date.now() - likes.angry.lastLikedAt >= 5 * 60 * 1000) {
                  handleRatingChange(0);
                  handleLike('angry');
                } else {
                  console.log('Можно ставить лайк только раз в 5 минут');
                }
              }}
            />
          )}
          <span className="like-count">{likes.angry.count}</span>
        </div>
      </div>
      <ExplanationModal
        show={showExplanation}
        explanation={explanationText}
        handleClose={handleCloseExplanation}
      />
    </div>
  );
};

export default SmileRating;
