import React, { useState, useEffect } from 'react';
import { Modal, Card, Typography, Button, Input, Form, Upload, message, Select, Row, Col, Alert, Spin, Tag, Space} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import './Form.css';
import { postMultipartRequest } from './Utils/api';
import categories from './constants/servicesForm';
import ru_sizes_women from './constants/sizeClothWomen';
import shoeSizesRU from './constants/choeSizes';
import { ProgressStepBar } from '@consta/uikit/ProgressStepBar';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';

const { TextArea } = Input;
const { Option } = Select;
const { Text, Link } = Typography;

const FormComponent = () => {
  const [form] = Form.useForm();
  const tg = window.Telegram.WebApp;
  const [generalInfo, setGeneralInfo] = useState({
    name: '',
    age: '',
    weight: '',
    height: '',
    gr_size: '',
    phones: '',
    whatsapp: '',
    tgname: tg.initDataUnsafe.user.username,//tg.initDataUnsafe.user.username, 
    comments: ''
  });

type Item = {
  label: string;
  point?: number;
  progress?: boolean;
  status?: 'normal' | 'success' | 'alert' | 'warning';
  lineStatus?: 'normal' | 'success' | 'alert' | 'warning';
};

const steps: Item[] = [
  {
    label: 'Ввод основных данных',
    point: 1,
    status: 'normal',
    progress: true,
    lineStatus: 'normal',
  },
  {
    label: 'Добавление фото',
    point: 2,
    status: 'normal',
    lineStatus: 'normal',
  },
  {
    label: 'Ожидание подтверждения',
    point: 3,
    status: 'normal',
    lineStatus: 'normal',
  },
];

const stepsTo: Item[] = [
  {
    label: 'Ввод основных данных',
    point: 1,
    status: 'normal',
    lineStatus: 'normal',
  },
  {
    label: 'Добавление фото',
    point: 2,
    status: 'normal',
    progress: true,
    lineStatus: 'normal',
  },
  {
    label: 'Ожидание подтверждения',
    point: 3,
    status: 'normal',
    lineStatus: 'normal',
  },
];
const steps3: Item[] = [
  {
    label: 'Ввод основных данных',
    point: 1,
    status: 'normal',
    lineStatus: 'normal',
  },
  {
    label: 'Добавление фото',
    point: 2,
    status: 'normal',
    lineStatus: 'normal',
  },
  {
    label: 'Ожидание подтверждения',
    point: 3,
    status: 'normal',
    lineStatus: 'normal',
  },
];

  const [services, setServices] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [photosApprove, setApprovePhotos] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [submittedFinish, setSubmittedFinish] = useState(null);
  const [approvedCode, setApproveCode] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedMetro, setSelectedMetro] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [isMetroModalVisible, setIsMetroModalVisible] = useState(false);
  const [isAreaModalVisible, setIsAreaModalVisible] = useState(false);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(`${BASE_URL}getCitys`);
        setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
        message.error('Ошибка при загрузке городов.');
      }
    };

    fetchCities();
  }, []);

  const handleGeneralInfoChange = (changedValues, allValues) => {
    setGeneralInfo(allValues);
  };

  const handleAddService = (service) => {
    if (!services.find(s => s.id === service.id)) {
      const newService = {
        id: service.id,
        name: service.name,
        price: ''
      };
      setServices(prevServices => [...prevServices, newService]);
      setModalVisible(false);
    }
  };

  const handleServicePriceChange = (value, serviceId) => {
    setServices(prevServices =>
      prevServices.map(service =>
        service.id === serviceId ? { ...service, price: value } : service
      )
    );
  };

  const handleRemoveService = (serviceId) => {
    setServices(prevServices => prevServices.filter(service => service.id !== serviceId));
  };

  const maxCount = 12;

  const handlePhotoChange = (info) => {
    let fileList = [...info.fileList];

    if (fileList.length > maxCount) {
      // Если файлов больше 15, оставляем только первые 15
      fileList = fileList.slice(0, maxCount);
      message.error(`Вы можете загрузить не более ${maxCount} фотографий.`);
    }

    // Обновляем статус файлов
    fileList = fileList.map(file => {
      if (file.status !== 'done') {
        file.status = 'done';
      }
      return file;
    });

    // Обновляем состояние с ограниченным количеством файлов
    setPhotos(fileList);
  };

  const handleRemoveApprovePhoto = (file) => {
    const newPhotos = photosApprove.filter(photo => photo.uid !== file.uid);
    setApprovePhotos(newPhotos);
  };
  const maxApproveCount = 2;
  const handlePhotoApproveChange = (info) => {
    let fileList = [...info.fileList];

    if (fileList.length > maxApproveCount) {
      // Если файлов больше 15, оставляем только первые 15
      fileList = fileList.slice(0, maxApproveCount);
      message.error(`Вы можете загрузить не более ${maxApproveCount} фотографий.`);
    }

    // Обновляем статус файлов
    fileList = fileList.map(file => {
      if (file.status !== 'done') {
        file.status = 'done';
      }
      return file;
    });

    // Обновляем состояние с ограниченным количеством файлов
    setApprovePhotos(fileList);
  };

  const handleRemovePhoto = (file) => {
    const newPhotos = photos.filter(photo => photo.uid !== file.uid);
    setPhotos(newPhotos);
  };
  const createSendPhoto = async () => {
    setLoading(true);
    const ids = JSON.stringify(submittedData, null, 2);
    const data = {
      id: ids,
      photos: photos.map(photo => photo.originFileObj),
      photosApprove: photosApprove.map(photo => photo.originFileObj)
    }
    console.log(data);
    try {
      const response = await postMultipartRequest('createSendModelsPhoto', data);
      console.log(response.success);
      if (response.success === 'ok') {
        setSubmittedFinish(ids);
        message.success('Успешно!');
      } else {
        throw new Error(response.error || 'Неизвестная ошибка');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Ошибка при отправке формы.');
    } finally {
      setLoading(false);  // Устанавливаем состояние загрузки в false после завершения
    }
    console.log(ids);
  }
  const createSend = async (values) => {
    setLoading(true);
    const data = {
      ...values,
      shoe: values.shoe,
      cloth: values.cloth,
      cityId: selectedCity ? selectedCity.id : null,
      metro: selectedMetro.map(metro => metro.id),
      areas: selectedAreas.map(area => area.id),
      services: services,
      //photos: photos.map(photo => photo.originFileObj),
      price: values.price,
      price2: values.price2,
      price3: values.price3,
      price_r: values.price_r,
      price_r2: values.price_r2,
      price_r3: values.price_r3
    };

    try {
      const response = await postMultipartRequest('createSendModels', data);
      

      if (response.id) {
        setSubmittedData(response.id);
        setApproveCode(response.approveCode);
        message.success('Выберите фотографии');
      } else {
        throw new Error(response.error || 'Неизвестная ошибка');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Ошибка при отправке формы.');
    } finally {
      setLoading(false);  // Устанавливаем состояние загрузки в false после завершения
    }
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleCityChange = (value) => {
    const city = cities.find(c => c.id === value);
    setSelectedCity(city);
    setSelectedMetro([]);
    setSelectedAreas([]);
  };

  const handleMetroChange = (value) => {
    if (selectedCity && selectedCity.metro) {
      const selected = selectedCity.metro.filter(metro => value.includes(metro.id));
      setSelectedMetro(selected);
    }
  };

  const handleAreasChange = (value) => {
    if (selectedCity && selectedCity.areas) {
      const selected = selectedCity.areas.filter(area => value.includes(area.id));
      setSelectedAreas(selected);
    }
  };
  if (submittedFinish) {
    return (

      <div className="submitted-data-container" style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <Card>
          <Theme preset={presetGpnDefault}>
    <ProgressStepBar steps={steps3} activeStepIndex={2} />
    </Theme>
        <Alert 
          message="Анкета отправлена на рассмотрение!" 
          type="success" 
          showIcon 
          style={{ marginTop: '20px' }}
        />
        <Space direction="vertical" style={{ marginTop: '20px' }}>
          <Text>
            ID отправленной анкеты: <Text strong>{submittedFinish}</Text>
          </Text>
          <Text>
            Передайте его менеджеру для дальнейшего одобрения. Все заявки строго проверяются администрацией.
          </Text>
          <Text>
            Поддержка: <Link href="https://t.me/vulgarcity_support">@vulgarcity_support</Link>
          </Text>
        </Space>
        <Button type="primary" href="/create" style={{ marginTop: '20px' }}>
          Домой
        </Button>
      </Card>
    </div>
    );
  }

  if (submittedData) {
    return (
    <div className="form-container">
        <Theme preset={presetGpnDefault}>
    <ProgressStepBar steps={stepsTo} activeStepIndex={1} />
    </Theme>
      <Form
        form={form}
        onFinish={createSendPhoto}
      >
  <div className="form-section">
    <h2 className="section-title">Фотографии</h2>
    <div className="info-services-section">
      <Alert message="Выберите до 12 фотографий для рассмотрения" type="info" />
    </div>
    <Form.Item>
      <Upload
        listType="picture-card"
        fileList={photos}
        onChange={handlePhotoChange}
        onRemove={handleRemovePhoto}
        beforeUpload={() => false}
        accept=".jpeg,.jpg"
        multiple
      >
        {photos.length < maxCount && (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Загрузить</div>
          </div>
        )}
      </Upload>
    </Form.Item>
  </div>
        <div className="form-section">
        <h2 className="section-title">Подтверждающие фотографии</h2>
          <div className="info-services-section">
            <Alert message="Ниже будет написан уникальный код подтверждения, его нужно будет написать на листке и сделать селфи." type="info" />
          </div>
          <div className="info-services-section">
            <Tag color="#f50">{approvedCode}</Tag>
          </div>
          <Form.Item>
            <Upload
              listType="picture-card"
              fileList={photosApprove}
              onChange={handlePhotoApproveChange}
              onRemove={handleRemoveApprovePhoto}
              beforeUpload={() => false}
              accept=".jpeg,.jpg"
              multiple
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Загрузить</div>
              </div>
            </Upload>
          </Form.Item>
        </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
              disabled={loading}  // Отключаем кнопку, если идет загрузка
            >
              {loading ? <Spin /> : 'Отправить'}  {/* Показываем индикатор загрузки */}
            </Button>
          </Form.Item>
        </Form>
      </div>
      /// <div className="submitted-data-container">
      //   <h2>Данные успешно отправлены</h2>
      //   <div>ID отправленной анкеты: {JSON.stringify(submittedData, null, 2)}, передайте его менеджеру для дальнейшего одобрения. Все анкеты строго проверяются администрацией <a href="https:/\//t.me/vulgarcity_support">@vulgarcity_support</a></div>
      //   <Button type="primary" href="/create">
      //     Домой
      //   </Button>
      // </div>
    );
  }

  return (
    <div className="form-container">
    <Theme preset={presetGpnDefault}>
    <ProgressStepBar steps={steps} activeStepIndex={0} />
    </Theme>
      <Form
        form={form}
        onValuesChange={handleGeneralInfoChange}
        onFinish={createSend}
        initialValues={generalInfo}
      >
        <div className="form-section">
          <h2 className="section-title">Общая информация</h2>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Имя" name="name" rules={[{ required: true, message: 'Введите имя' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Возраст" name="age" rules={[{ required: true, message: 'Введите возраст' }]}>
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Вес" name="weight" rules={[{ required: true, message: 'Введите вес' }]}>
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Рост" name="height" rules={[{ required: true, message: 'Введите рост' }]}>
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Грудь" name="gr_size" rules={[{ required: true, message: 'Введите размер груди' }]}>
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Телефон" name="phones" rules={[{ required: true, message: 'Введите номер телефона' }]}>
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="WhatsApp" name="whatsapp" rules={[{ required: false, message: 'Введите номер ватсапа' }]}>
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Телеграм" name="tgname" rules={[{ required: true, message: 'Введите username для анкеты' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Размер одежды" name="cloth" rules={[{ required: true, message: 'Выберите размер одежды' }]}>
            <Select placeholder="Выберите размер">
              {ru_sizes_women.map((size, index) => (
                <Option key={index} value={index}>
                  {size}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Размер обуви" name="shoe" rules={[{ required: true, message: 'Выберите размер обуви' }]}>
            <Select placeholder="Выберите размер">
              {shoeSizesRU.map((size, index) => (
                <Option key={index} value={index}>
                  {size}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Описание"
                name="comments"
                rules={[{ required: true, message: 'Введите описание' }]}
              >
                <TextArea
                  showCount  // Показать счетчик символов
                  maxLength={4000}  // Максимальная длина ввода
                  placeholder="Введите описание"
                  style={{ height: 180, resize: 'none' }}  // Стилизация: фиксированная высота и запрет на изменение размера
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
    <div className="form-section">
      <div className="subcategories-container">
        <div className="subcategory">
          <h3 className="subcategory-title">Аппартаменты</h3>
          <div className="form-items-container">
            <div className="form-item">
              <Form.Item label="Час" name="price" rules={[{ required: true, message: 'Введите стоимость часа' }]}>
                <Input type="number" />
              </Form.Item>
            </div>
            <div className="form-item">
              <Form.Item label="2 часа" name="price2" rules={[{ required: true, message: 'Введите стоимость 2 часов' }]}>
                <Input type="number" />
              </Form.Item>
            </div>
            <div className="form-item">
              <Form.Item label="Ночь" name="price3" rules={[{ required: true, message: 'Введите стоимость ночи' }]}>
                <Input type="number" />
              </Form.Item>
            </div>
          </div>
        </div>
        
        {/* Вторая подкатегория */}
        <div className="subcategory">
          <h3 className="subcategory-title">Выезд</h3>
          <div className="form-items-container">
            <div className="form-item">
              <Form.Item label="Час" name="price_r" rules={[{ required: true, message: 'Введите стоимость часа' }]}>
                <Input type="number" />
              </Form.Item>
            </div>
            <div className="form-item">
              <Form.Item label="2 часа" name="price_r2" rules={[{ required: true, message: 'Введите стоимость 2 часов' }]}>
                <Input type="number" />
              </Form.Item>
            </div>
            <div className="form-item">
              <Form.Item label="Ночь" name="price_r3" rules={[{ required: true, message: 'Введите стоимость ночи' }]}>
                <Input type="number" />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="form-section">
        <h2 className="section-title">Услуги</h2>
        <div className="info-services-section">
          <Alert message="Выберите и укажите цену на предоставляемые услуги. Если услуга предоставляется, но цены нет - оставьте поле цена пустым. Если услуга не предоставляется, то не выбирайте её вовсе." type="info" />
          </div>
          {services.map(service => (
            <div key={service.id} className="service-item">
              <span>{service.name}</span>
              <Input
                type="number"
                placeholder="Цена"
                value={service.price}
                onChange={(e) => handleServicePriceChange(e.target.value, service.id)}
                className="service-price-input"
              />
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveService(service.id)}
              />
            </div>
          ))}
        <Button type="dashed" onClick={showModal} style={{ width: '100%' }}>
          <PlusOutlined /> Добавить услугу
        </Button>
      </div>


        <Modal
          title="Выберите услугу"
          visible={modalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="categories-container">
            {categories.map(category => (
              <div key={category.id} className="category">
                <h3>{category.name}</h3>
                {category.services.map(service => (
                  <Button
                    key={service.id}
                    type="dashed"
                    onClick={() => handleAddService(service)}
                    style={{ width: '100%', marginBottom: '0.5rem' }}
                  >
                    {service.name}
                  </Button>
                ))}
              </div>
            ))}
          </div>
        </Modal>



        <div className="form-section">
          <h2 className="section-title">Город</h2>
          <Select
            placeholder="Выберите город"
            style={{ width: '100%', marginBottom: '1rem' }}
            onChange={handleCityChange}
            value={selectedCity ? selectedCity.id : undefined}
          >
            {cities.map(city => (
              <Option key={city.id} value={city.id}>
                {city.name}
              </Option>
            ))}
          </Select>

          {selectedCity && selectedCity.metro && selectedCity.metro.length > 0 && (

            <Button type="dashed" onClick={() => setIsMetroModalVisible(true)} style={{ marginBottom: '1rem', marginRight: '1rem'  }}>
              <PlusOutlined /> Добавить Метро
            </Button>
          )}

          {selectedCity && selectedCity.areas && selectedCity.areas.length > 0 && (
            <Button type="dashed" onClick={() => setIsAreaModalVisible(true)} style={{ marginBottom: '1rem', marginRight: '2rem' }}>
             <PlusOutlined /> Добавить Районы
            </Button>
          )}

          <Modal
            title="Выберите Метро"
            visible={isMetroModalVisible}
            onCancel={() => setIsMetroModalVisible(false)}
            onOk={() => setIsMetroModalVisible(false)}
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Выберите метро"
              onChange={handleMetroChange}
              value={selectedMetro.map(m => m.id)}
            >
              {selectedCity && selectedCity.metro && selectedCity.metro.map(metro => (
                <Option key={metro.id} value={metro.id}>
                  {metro.name}
                </Option>
              ))}
            </Select>
          </Modal>

          <Modal
            title="Выберите Районы"
            visible={isAreaModalVisible}
            onCancel={() => setIsAreaModalVisible(false)}
            onOk={() => setIsAreaModalVisible(false)}
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Выберите районы"
              onChange={handleAreasChange}
              value={selectedAreas.map(a => a.id)}
            >
              {selectedCity && selectedCity.areas && selectedCity.areas.map(area => (
                <Option key={area.id} value={area.id}>
                  {area.name}
                </Option>
              ))}
            </Select>
          </Modal>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            disabled={loading}  // Отключаем кнопку, если идет загрузка
          >
            {loading ? <Spin /> : 'Далее'}  {/* Показываем индикатор загрузки */}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormComponent;
