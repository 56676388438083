import React from 'react';
import './Footer.css';
import { FaTelegram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-divider"></div>
      <div className="footer-content">
        <div className="footer-left">
          <h4>18+</h4>
          <p>
            ДАННЫЙ РЕСУРС ПРЕДНАЗНАЧЕН ДЛЯ ОБЩЕНИЯ И ПОИСКА ЛЮДЕЙ ПО ИНТЕРЕСАМ, И ТОЛЬКО ДЛЯ ЛИЦ СОВЕРШЕННОЛЕТНЕГО ВОЗРАСТА!
            Администрация ресурса не размещает анкеты и ответственности за их содержание не несет!
          </p>
          <p>ПО ВСЕМ ВОПРОСАМ ОБРАЩАЙТЕСЬ В ПОДДЕРЖКУ</p>
        </div>
        <div className="footer-right">
          <a href='https://t.me/vulgarcity_support' className="telegram-link">
            <FaTelegram className="telegram-icon" />
            @vulgarcity_support
          </a>
        </div>
      </div>
      <div className="footer-links">
        <a href="/terms">Пользовательское соглашение</a>
        <a href="/privacy">Политика конфиденциальности</a>
        <a href="/other-info">Другая информация</a>
      </div>
    </footer>
  );
};

export default Footer;
