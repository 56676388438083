// src/components/Sidebar.js
import React, { useState, useEffect } from 'react';
import Sidebar from 'react-sidebar';
import { FaHome, FaUser, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';
import './SidebarProduct.css'; // Импорт стилей
import { Link } from 'react-router-dom';

const CustomSidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  useEffect(() => {
    document.body.style.overflow = sidebarOpen ? 'hidden' : 'auto';
  }, [sidebarOpen]);

  return (
    <div>
      <Sidebar
        sidebar={
          <div className="sidebar-content">
            <div className="header">
              <img src="https://graansen.com/public/modelImages/watermark.png" alt="Logo" className="logo" />
              <button className="toggle-btn" onClick={() => onSetSidebarOpen(false)}>
                <GoSidebarExpand />
              </button>
            </div>
            <div className="menu">
                                          <Link to="/" className="no-select no-drag">
                <FaHome /> Главная
              </Link>
            </div>

          </div>
        }
        open={sidebarOpen}
        onSetOpen={onSetSidebarOpen}
        styles={{ sidebar: { background: "#ffffff", width: 250 }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.3)" } }}
      >
        <button className="open-btn" onClick={() => onSetSidebarOpen(true)}>
          <GoSidebarCollapse />
        </button>
      </Sidebar>
    </div>
  );
};

export default CustomSidebar;
