  const serviceNames = {
    cat1_s1: 'Секс классический',
    cat1_s2: 'Секс анальный',
    cat1_s3: 'Секс групповой',
    cat1_s4: 'Услуги семейной паре',
    cat1_s5: 'Минет в презервативе',
    cat1_s6: 'Минет без резинки',
    cat1_s7: 'Минет глубокий',
    cat1_s8: 'Куннилингус',
    cat1_s9: 'Окончание в рот',
    cat1_s10: 'Окончание на занавеску',
    cat2_s1: 'Фото/видео съёмка',
    cat2_s2: 'Виртуальный секс',
    cat2_s3: 'Секс по телефону',
    cat3_s1: 'Страпон',
    cat3_s2: 'Аналингус вам',
    cat3_s3: 'Анилингус мне',
    cat3_s4: 'Золотой дождь выдача',
    cat3_s5: 'Золотой дождь приём',
    cat3_s6: 'Копро вам',
    cat4_s1: 'Госпожа',
    cat4_s2: 'Игры',
    cat4_s3: 'Лёгкая доминация',
    cat4_s4: 'Порка',
    cat4_s5: 'Рабыня',
    cat4_s6: 'Фетиш',
    cat4_s7: 'Лёгкая рабыня',
    // добавьте или измените названия категорий по вашему усмотрению
  };

  export default serviceNames;