  const categories = [
    {
      id: 1,
      name: 'Секс',
      services: [
        { id: 1, name: 'Секс классический' },
        { id: 2, name: 'Секс анальный' },
        { id: 3, name: 'Секс групповой' },
        { id: 4, name: 'Услуги семейной паре' },
        { id: 5, name: 'Минет в презервативе' },
        { id: 6, name: 'Минет без резинки' },
        { id: 7, name: 'Минет глубокий' },
        { id: 8, name: 'Кунилингус' },
        { id: 9, name: 'Окончание в рот' }
      ]
    },
    {
      id: 2,
      name: 'Разное',
      services: [
        { id: 10, name: 'Фото/видео съёмка' },
        { id: 11, name: 'Виртуальный секс' },
        { id: 12, name: 'Секс по телефону' }
      ]
    },
    {
      id: 3,
      name: 'Экстрим',
      services: [
        { id: 13, name: 'Страпон' },
        { id: 14, name: 'Анилингус вам' },
        { id: 15, name: 'Анилингус мне' },
        { id: 16, name: 'Золотой дождь выдача' },
        { id: 17, name: 'Золотой дождь приём' },
        { id: 18, name: 'Копро вам' }
      ]
    },
    {
      id: 4,
      name: 'Садо-мазо',
      services: [
        { id: 19, name: 'Госпожа' },
        { id: 20, name: 'Игры' },
        { id: 21, name: 'Лёгкая доминация' },
        { id: 22, name: 'Порка' },
        { id: 23, name: 'Рабыня' },
        { id: 24, name: 'Фетиш' },
        { id: 25, name: 'Лёгкая рабыня' }
      ]
    }
  ];

export default categories;
