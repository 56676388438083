import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Подключаем файл стилей для страницы 404

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-header">404</h1>
      <p className="not-found-text">Извините, страница не найдена</p>
      <Link to="/" className="not-found-link">На главную</Link>
    </div>
  );
};

export default NotFound;
