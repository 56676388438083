import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Carousel } from 'antd';
import { FaWhatsapp, FaTelegramPlane, FaArrowLeft, FaPhone } from 'react-icons/fa';
import { Hearts } from 'react-loader-spinner';
import { useSwipeable } from 'react-swipeable';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { Informer } from '@consta/uikit/Informer';
import { IconAlert } from '@consta/icons/IconAlert';
import './ProductDetail.css';
import ExplanationModal from './ExplanationModal';
import ProductInfo from './ProductInfo';
import ServicesList from './ServicesList';
import TabsContent from './TabsContent';
import { postMultipartRequest } from './Utils/api';
import SmileRating from './SmileRating';
import { AiOutlineClose } from 'react-icons/ai';
import Footer from './Footer';
import Sidebar from './sidebar/SidebarProduct';


const ProductDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showExplanation, setShowExplanation] = useState(false);
  const [explanationText, setExplanationText] = useState('');
  const [showPhone, setShowPhone] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [rating, setRating] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const tg = window.Telegram.WebApp;
  const tgId = tg.initDataUnsafe.user.id;


  
 const handleExplanationClick = (text) => {
    setExplanationText(text);
    setShowExplanation(true);
  };
  const handleBackButtonClick = () => {
    navigate(`/`, { state: location.state });
  };

  const handleCloseExplanation = () => {
    setShowExplanation(false);
  };

    const handlePhoneCall = () => {
    window.open(`tel:${product.phone}`);
  };

  const handlers = useSwipeable({
    onSwipedRight: () => navigate('/', { state: location.state }),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });



  const handleShowPhone = async () => {
    try {
      await postMultipartRequest('showPhone', { model_id: id, tg_id: tgId });
      setShowPhone(true);
    } catch (error) {
      console.error('Ошибка при показе телефона:', error);
    }
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handlePhotoClick = (index) => {
    setSelectedIndex(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelect = (selectedIndex, e) => {
    setSelectedIndex(selectedIndex);
  };

  const showTg = async () => {
    try {
      await postMultipartRequest('showTg', { model_id: id, tg_id: tgId });
    } catch (error) {
      console.error('Ошибка', error);
    }
  };

  const showWs = async () => {
    try {
      await postMultipartRequest('showWs', { model_id: id, tg_id: tgId });
    } catch (error) {
      console.error('Ошибка', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchProduct = async () => {
      try {
        const response = await fetch(`${BASE_URL}getModelById?id=${id}`);
        const data = await response.json();

        if (!data || !data[0]) {
          throw new Error('Invalid data format');
        }

        let transformedServices = {};

        if (data[0].service[0]) {
          const services = data[0].service[0];
          transformedServices = Object.entries(services).reduce((acc, [key, value]) => {
            if (key === 'model_id') {
              return acc;
            }
            const [category, service] = key.split('_');
            if (!acc[category]) {
              acc[category] = {};
            }
            acc[category][service] = value === '0' ? false : value === '1' ? true : value > '1' ? `${value}` : value;
            return acc;
          }, {});
        } else {
          transformedServices = { услуги: false };
        }

        let metroStations = '';
        if (data[0].metro && data[0].metro.stations) {
          const stationsArray = data[0].metro.stations.map(stationArray => stationArray[0]);
          metroStations = stationsArray.join(', ');
        }

        let areaNames = '';
        if (data[0].area && data[0].area.area_names) {
          const areasArray = data[0].area.area_names.map(areaArray => areaArray[0]);
          areaNames = areasArray.join(', ');
        }

        const transformedData = {
          id: parseInt(data[0].id, 10),
          name: data[0].name,
          age: data[0].age ? parseInt(data[0].age, 10) : 'N/A',
          height: data[0].height ? parseInt(data[0].height, 10) : 'N/A',
          weight: data[0].weight ? parseInt(data[0].weight, 10) : 'N/A',
          phone: data[0].phone ? parseInt(data[0].phone, 10) : 'N/A',
          gr_size: data[0].gr_size ? parseInt(data[0].gr_size, 10) : 'N/A',
          choe: data[0].choe ? parseInt(data[0].choe, 10) : 'N/A',
          cloth: data[0].cloth ? parseInt(data[0].cloth, 10) : 'N/A',
          price: data[0].price ? parseFloat(data[0].price) : 'N/A',
          price2: data[0].price2 ? parseFloat(data[0].price2) : 'N/A',
          price3: data[0].price3 ? parseFloat(data[0].price3) : 'N/A',
          price_r: data[0].price_r ? parseFloat(data[0].price_r) : 'N/A',
          price_r2: data[0].price_r2 ? parseFloat(data[0].price_r2) : 'N/A',
          price_r3: data[0].price_r3 ? parseFloat(data[0].price_r3) : 'N/A',
          description: data[0].comments || '',
          tgname: data[0].tgname || '',
          whatsapp: data[0].whatsapp || '',
          photos: data[0].photos || '',
          services: transformedServices,
          metroStations: metroStations,
          areaNames: areaNames,
        };

        setProduct(transformedData);
        setLoading(false);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
        setLoading(false);
      }
    };


    fetchProduct();
  }, [id]); // Обновление useEffect при изменении id или location.state


  if (loading) {
    return (
      <div className="loading-container">
        <Hearts color="#FF0000" height={150} width={150} />
      </div>
    );
  }

  if (!product) {
    return <div>Ошибка при загрузке данных.</div>;
  }

  return (
    <div className="product-detail-container">
    <Sidebar />
      <div className="header">
        <button className="back-button fixed" onClick={handleBackButtonClick}>
          <FaArrowLeft />
        </button>
      </div>
    <Carousel arrows adaptiveHeight="true" className="product-carousel no-drag no-select no-context-menu">
      {product.photos && product.photos.length > 0 ? (
        product.photos.map((photo, index) => (
          <div key={index}>
            <div className="carousel-image-container">
              <img
                className="carousel-image no-drag"
                src={photo.photo_url}
                alt={`Slide ${index + 1}`}
                onClick={() => handlePhotoClick(index)}
              />
            </div>
          </div>
        ))
      ) : (
        <div>
          <div className="carousel-image-container">
            <img
              className="carousel-image"
              src="https://via.placeholder.com/300"
              alt="No Image"
            />
          </div>
        </div>
      )}
    </Carousel>
    <div {...handlers}>
      <ProductInfo product={product} handleExplanationClick={handleExplanationClick} />
      <div className="product-detail-container">
        <SmileRating initialValue={rating} onRatingChange={handleRatingChange} modelId={id} tgId={tgId} />
      </div>
      <TabsContent activeTab={activeTab} setActiveTab={setActiveTab} product={product} />

      <div className="description">
        <div className="quote">
          <div className="quote-mark left-quote">“</div>
          <p>{product.description}</p>
          <div className="quote-mark right-quote">”</div>
        </div>
      </div>

      <ServicesList product={product} />

      <div className="contact-icons">
        <Theme preset={presetGpnDefault}>
          <Informer
            label="Никогда и не при каких обстоятельствах не переводите предоплату, даже если вас об этом очень сильно просят"
            view="bordered"
            status="alert"
            icon={IconAlert}
          />
        </Theme>
        {!showPhone && (
          <button className="contact-button" onClick={handleShowPhone}>
            Показать телефон <FaPhone />
          </button>
        )}
        {showPhone && (
    <div className="test">
      <div className="phone-number" onClick={handlePhoneCall} style={{ cursor: 'pointer' }}>
        <FaPhone className="phone-number-icon" /> {product.phone}
      </div>
    </div>
        )}
        <div className="icon-container">
          {product.whatsapp && (
            <a href={`https://wa.me/${product.whatsapp}`} target="_blank" rel="noopener noreferrer" onClick={showWs}>
              <FaWhatsapp className="contact-icon whatsapp" />
            </a>
          )}
          {product.tgname && (
            <a href={`https://t.me/${product.tgname}`} target="_blank" rel="noopener noreferrer" onClick={showTg}>
              <FaTelegramPlane className="contact-icon telegram" />
            </a>
          )}
        </div>
        </div>
      </div>

      <ExplanationModal
        show={showExplanation}
        explanation={explanationText}
        handleClose={handleCloseExplanation}
      />

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Body>
          <Carousel activeIndex={selectedIndex} onSelect={handleSelect} indicators={false}>
            {product.photos.map((photo, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100 modal-carousel-image"
                  src={photo.photo_url}
                  alt={`Slide ${index + 1}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <button className="close-button" onClick={handleCloseModal}><AiOutlineClose /></button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
};

export default ProductDetail;
