import React, { useState } from 'react';
import { Modal, Button, Input, Form, Upload, message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { postMultipartRequest } from './Utils/api';
import './Form.css';

const { TextArea } = Input;

const FormComponent = () => {
  const [form] = Form.useForm();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);  // Состояние загрузки
  const [submittedData, setSubmittedData] = useState(null);

  const maxCount = 15;

  const handlePhotoChange = (info) => {
    let fileList = [...info.fileList];

    if (fileList.length > maxCount) {
      // Если файлов больше 15, оставляем только первые 15
      fileList = fileList.slice(0, maxCount);
      message.error(`Вы можете загрузить не более ${maxCount} фотографий.`);
    }

    // Обновляем статус файлов
    fileList = fileList.map(file => {
      if (file.status !== 'done') {
        file.status = 'done';
      }
      return file;
    });

    // Обновляем состояние с ограниченным количеством файлов
    setPhotos(fileList);
  };

  const handleRemovePhoto = (file) => {
    const newPhotos = photos.filter(photo => photo.uid !== file.uid);
    setPhotos(newPhotos);
  };

  const createSend = async (values) => {
    setLoading(true);  // Устанавливаем состояние загрузки в true
    const data = {
      photos: photos.map(photo => photo.originFileObj), 
    };

    try {
      const response = await postMultipartRequest('testCreateSend', data);
      
      // Очистка ответа от лишних скриптов и стилей
      const cleanedResponse = response.replace(/<script\b[^>]*>(.*?)<\/script>/gi, '')
                                      .replace(/<style\b[^>]*>(.*?)<\/style>/gi, '')
                                      .replace(/[\r\n]+/g, '');

      // Парсинг очищенного JSON-ответа
      const jsonResponse = JSON.parse(cleanedResponse);

      if (jsonResponse.id) {
        setSubmittedData(jsonResponse.id);
        message.success('Заявка успешно отправлена! Пожалуйста, не отправляйте повторных заявок!');
      } else {
        throw new Error(jsonResponse.error || 'Неизвестная ошибка');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Ошибка при отправке формы.');
    } finally {
      setLoading(false);  // Устанавливаем состояние загрузки в false после завершения
    }
  };

  return (
    <div className="form-container">
      <Form
        form={form}
        onFinish={createSend}
      >
        <div className="form-section">
          <h2 className="section-title">Фотографии</h2>
          <Form.Item>
            <Upload
              listType="picture-card"
              fileList={photos}
              onChange={handlePhotoChange}
              onRemove={handleRemovePhoto}
              beforeUpload={() => false}
              accept=".jpeg,.jpg"
              multiple
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Загрузить</div>
              </div>
            </Upload>
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            disabled={loading}  // Отключаем кнопку, если идет загрузка
          >
            {loading ? <Spin /> : 'Отправить'}  {/* Показываем индикатор загрузки */}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormComponent;
