import React, { useState, useEffect } from 'react';
import { Collapse, Row, Col, Form, Input, Select, Button, Switch, Flex } from 'antd';
import { BsQuestionCircle } from 'react-icons/bs';
import ExplanationModal from './ExplanationModal';
import ru_sizes_women from './constants/sizeClothWomen';
import shoeSizesRU from './constants/choeSizes';
import { DeleteOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { Option } = Select;

const Filter = ({ onFilter, metroStations, areas }) => {
  const [form] = Form.useForm();
  const [showExplanation, setShowExplanation] = useState(false);
  const [explanationText, setExplanationText] = useState('');

  useEffect(() => {
    // Загрузка сохраненных фильтров при монтировании компонента
    const savedFilters = loadFiltersFromLocalStorage();
    form.setFieldsValue(savedFilters);
  }, [form]);

  const handleFilter = (values) => {
    // Сохранение фильтров в Local Storage
    saveFiltersToLocalStorage(values);
    onFilter(values);
  };

  const handleReset = () => {
    // Очистка значений формы
    form.resetFields();
    
    // Удаление фильтров из Local Storage
    localStorage.removeItem('filters');
    
    // Вызов функции фильтрации с пустыми значениями
    onFilter({});
  };

  const handleExplanationClick = (text) => {
    setExplanationText(text);
    setShowExplanation(true);
  };

  const handleCloseExplanation = () => {
    setShowExplanation(false);
  };

  const saveFiltersToLocalStorage = (filters) => {
    localStorage.setItem('filters', JSON.stringify(filters));
  };

  const loadFiltersFromLocalStorage = () => {
    const savedFilters = localStorage.getItem('filters');
    return savedFilters ? JSON.parse(savedFilters) : {};
  };

  return (
    <Collapse defaultActiveKey={['0']} className="mb-4 filter-accordion">
      <Panel header="Фильтр" key="0">
        <div className="accordion-body">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFilter}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Цена (₽) <BsQuestionCircle onClick={() => handleExplanationClick("Введите диапазон цен в рублях.")} className="icon-explanation" />
                    </span>
                  }
                  name="priceRange"
                >
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item name="priceFrom" noStyle>
                        <Input placeholder="От" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="priceTo" noStyle>
                        <Input placeholder="До" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Вес (кг) <BsQuestionCircle onClick={() => handleExplanationClick("Введите диапазон веса в килограммах.")} className="icon-explanation" />
                    </span>
                  }
                  name="weightRange"
                >
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item name="weightFrom" noStyle>
                        <Input placeholder="От" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="weightTo" noStyle>
                        <Input placeholder="До" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Рост (см) <BsQuestionCircle onClick={() => handleExplanationClick("Введите диапазон роста в сантиметрах.")} className="icon-explanation" />
                    </span>
                  }
                  name="heightRange"
                >
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item name="heightFrom" noStyle>
                        <Input placeholder="От" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="heightTo" noStyle>
                        <Input placeholder="До" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Грудь <BsQuestionCircle onClick={() => handleExplanationClick("Введите диапазон грудной клетки.")} className="icon-explanation" />
                    </span>
                  }
                  name="grSizeRange"
                >
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item name="grSizeFrom" noStyle>
                        <Input placeholder="От" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="grSizeTo" noStyle>
                        <Input placeholder="До" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>

              {metroStations.length > 0 && (
                <Col span={12}>
                  <Form.Item
                    label={
                      <span>
                        Станция метро <BsQuestionCircle onClick={() => handleExplanationClick("Выберите станцию метро для фильтрации анкет.")} className="icon-explanation" />
                      </span>
                    }
                    name="metroStationId"
                  >
                    <Select
                      showSearch
                      placeholder="Выберите станцию"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="">Все станции</Option>
                      {metroStations.map((station) => (
                        <Option key={station.id} value={station.id}>
                          {station.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {areas.length > 0 && (
                <Col span={12}>
                  <Form.Item
                    label={
                      <span>
                        Район <BsQuestionCircle onClick={() => handleExplanationClick("Выберите район для фильтрации анкет.")} className="icon-explanation" />
                      </span>
                    }
                    name="areaId"
                  >
                    <Select placeholder="Выберите район">
                      <Option value="">Все районы</Option>
                      {areas.map((area) => (
                        <Option key={area.id} value={area.id}>
                          {area.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Размер одежды <BsQuestionCircle onClick={() => handleExplanationClick("Выберите размер одежды для фильтрации анкет.")} className="icon-explanation" />
                    </span>
                  }
                  name="sizeCloth"
                >
                  <Select placeholder="Выберите размер">
                    <Option value="">Все размеры</Option>
                    {ru_sizes_women.map((size, index) => (
                      <Option key={index} value={index}>
                        {size}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Размер обуви <BsQuestionCircle onClick={() => handleExplanationClick("Выберите размер обуви для фильтрации анкет.")} className="icon-explanation" />
                    </span>
                  }
                  name="choeSizes"
                >
                  <Select placeholder="Выберите размер">
                    <Option value="">Все размеры</Option>
                    {shoeSizesRU.map((size, index) => (
                      <Option key={index} value={index}>
                        {size}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Новые анкеты <BsQuestionCircle onClick={() => handleExplanationClick("Отметьте, чтобы фильтровать только новые анкеты.")} className="icon-explanation" />
                    </span>
                  }
                  name="newQuest"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
             <Flex gap="small" wrap>
              <Button type="dashed" htmlType="submit">
                Применить фильтр
              </Button>
              <Button type="dashed" icon={<DeleteOutlined />} onClick={handleReset}>
               
              </Button>
              </Flex>
            </Form.Item>
          </Form>
        </div>
      </Panel>

      <ExplanationModal
        show={showExplanation}
        explanation={explanationText}
        handleClose={handleCloseExplanation}
      />
    </Collapse>
  );
};

export default Filter;
