import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import { User } from '@consta/uikit/User';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { Pagination, Table } from 'antd';
import { MdVerified } from "react-icons/md";
import { FaTelegramPlane } from 'react-icons/fa';
import { IoLogoWhatsapp, IoScaleSharp, IoResizeOutline } from "react-icons/io5";
import { FcBusinesswoman } from "react-icons/fc";
import { GiBodyHeight } from "react-icons/gi";
import { SiMoscowmetro } from "react-icons/si";
import { PiMapPinAreaDuotone } from "react-icons/pi";
import { Hearts } from 'react-loader-spinner';
import Filter from './Filter';
import ExplanationModal from './ExplanationModal';
import './ProductDetail.css';
import Footer from './Footer';
import { createBrowserHistory } from 'history';
import CustomHomeSidebar from './sidebar/SidebarHome';


import { Badge } from "antd";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productsBase, setProductsBase] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showExplanation, setShowExplanation] = useState(false);
  const [explanationText, setExplanationText] = useState('');
  const [metroStations, setMetroStations] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedMetroStation, setSelectedMetroStation] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null); // Добавляем состояние для района
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isNoModels, setIsNoModels] = useState(false);
  const [cityId, setCityId] = useState([]);
  const navigate = useNavigate(); 
  const location = useLocation();
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const tg = window.Telegram.WebApp;
  const tgId = tg.initDataUnsafe.user.id;

  const handleExplanationClick = (text) => {
    setExplanationText(text);
    setShowExplanation(true);
  };

  const handleCloseExplanation = () => {
    setShowExplanation(false);
  };

  const handleProductClick = (productId) => {
    const scrollPosition = window.scrollY;

    navigate(`/product/${productId}`, { state: { filteredProducts, scrollPosition, currentPage } });
  };


  const handleClearState = () => {
    const history = createBrowserHistory();
    const { location } = history;

    if (location.state) {
      history.replace({
        ...location,
        state: undefined // или undefined, чтобы удалить location.state
      });
    }
  }


  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}getModels?tg_id=${tgId}`);
        const data = await response.json();

        if (data.status === 'user_blocked') {
          setIsBlocked(true);
          setLoading(false);
          return;
        }
        if (data.status === 'no_models') {
          setIsNoModels(true);
          setLoading(false);
          return;
        }
        const transformedData = data.models.map(item => ({
          id: parseInt(item.id, 10),
          name: item.name,
          priority: parseInt(item.priority, 10),
          age: parseInt(item.age, 10),
          weight: parseInt(item.weight, 10),
          height: parseInt(item.height, 10),
          gr_size: parseInt(item.gr_size, 10),
          status: parseInt(item.status, 10),
          cloth: parseInt(item.cloth, 10),
          choe: parseInt(item.choe, 10),
          price: parseFloat(item.price),
          price2: parseFloat(item.price2),
          price3: parseFloat(item.price3),
          images: item.photos.length > 0 ? item.photos.map(photo => photo.photo_url) : ['https://via.placeholder.com/300'],
          metro: item.metro.map(metroId => parseInt(metroId, 10)),
          area: item.area.map(areaId => parseInt(areaId, 10)),
          newQuest: parseInt(item.newQuest, 10),
          tgname: item.tgname,
          whatsapp: item.whatsapp,
        }));
        setCityId(data.city);

        // Группируем по приоритету
        const highPriority = transformedData.filter(item => item.priority >= 8);
        const mediumPriority = transformedData.filter(item => item.priority >= 5 && item.priority < 8);
        const lowPriority = transformedData.filter(item => item.priority < 5);

        // Перемешиваем каждую группу отдельно
        const shuffledHighPriority = shuffleArray(highPriority);
        const shuffledMediumPriority = shuffleArray(mediumPriority);
        const shuffledLowPriority = shuffleArray(lowPriority);

        // Объединяем группы с большей рандомизацией
        const mergedArray = [];
        const totalLength = shuffledHighPriority.length + shuffledMediumPriority.length + shuffledLowPriority.length;

        // Индексы для каждой группы
        let highIndex = 0;
        let mediumIndex = 0;
        let lowIndex = 0;

        while (mergedArray.length < totalLength) {
          const randomChoice = Math.random();
          if (randomChoice < 0.4 && highIndex < shuffledHighPriority.length) {
            mergedArray.push(shuffledHighPriority[highIndex++]);
          } else if (randomChoice < 0.7 && mediumIndex < shuffledMediumPriority.length) {
            mergedArray.push(shuffledMediumPriority[mediumIndex++]);
          } else if (lowIndex < shuffledLowPriority.length) {
            mergedArray.push(shuffledLowPriority[lowIndex++]);
          } else if (mediumIndex < shuffledMediumPriority.length) {
            mergedArray.push(shuffledMediumPriority[mediumIndex++]);
          } else if (highIndex < shuffledHighPriority.length) {
            mergedArray.push(shuffledHighPriority[highIndex++]);
          }
        }

        setProductsBase(transformedData);
        
        if (location.state && location.state.filteredProducts && Array.isArray(location.state.filteredProducts) && location.state.filteredProducts.length > 0) {
          setFilteredProducts(location.state.filteredProducts);
          setProducts(location.state.filteredProducts);
          setTimeout(() => {
            setCurrentPage(location.state.currentPage);
            window.scrollTo({ top: location.state.scrollPosition });
            handleClearState();
          }, 0);
        } else {
          setProducts(mergedArray);
          setFilteredProducts(mergedArray);
        }

        setMetroStations(data.metro_stations.map(station => ({
          id: parseInt(station.id, 10),
          name: station.name
        })));

        setAreas(data.area_names.map(area => ({
          id: parseInt(area.id, 10),
          name: area.name
        })));

        setLoading(false);

      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [location.state]);



const handleFilter = ({ priceFrom, priceTo, weightFrom, weightTo, heightFrom, heightTo, grSizeFrom, grSizeTo, sizeCloth, choeSizes, metroStationId, areaId, newQuest }) => {
    setSelectedMetroStation(metroStationId);
    setSelectedArea(areaId); // Сохраняем выбранный район

    let filtered = productsBase.filter(product => {
        let isPriceInRange = true;
        let isWeightInRange = true;
        let isHeightInRange = true;
        let isGrSizeInRange = true;
        let isClothInRange = true;
        let isChoeInRange = true;
        let isNewQuest = true;

        if (priceFrom && product.price < parseFloat(priceFrom)) {
            isPriceInRange = false;
        }
        if (priceTo && product.price > parseFloat(priceTo)) {
            isPriceInRange = false;
        }
        if (weightFrom && product.weight < parseFloat(weightFrom)) {
            isWeightInRange = false;
        }
        if (weightTo && product.weight > parseFloat(weightTo)) {
            isWeightInRange = false;
        }
        if (heightFrom && product.height < parseFloat(heightFrom)) {
            isHeightInRange = false;
        }
        if (heightTo && product.height > parseFloat(heightTo)) {
            isHeightInRange = false;
        }
        if (grSizeFrom && product.gr_size < parseFloat(grSizeFrom)) {
            isGrSizeInRange = false;
        }
        if (grSizeTo && product.gr_size > parseFloat(grSizeTo)) {
            isGrSizeInRange = false;
        }
        if (metroStationId && !product.metro.includes(parseInt(metroStationId, 10))) {
            isPriceInRange = false;
        }
        if (areaId && !product.area.includes(parseInt(areaId, 10))) {
            isPriceInRange = false;
        }
        if (sizeCloth && parseInt(sizeCloth, 10) !== product.cloth) {
            isClothInRange = false;
        }
        if (choeSizes && parseInt(choeSizes, 10) !== product.choe) {
            isChoeInRange = false;
        }

        // Фильтрация по новизне товара
        if (newQuest !== undefined) {
            if (newQuest && !product.newQuest) {
                isNewQuest = false;
            }
            if (!newQuest && product.newQuest) { 
                isNewQuest = false;
            }
        }

        return isPriceInRange && isWeightInRange && isHeightInRange && isGrSizeInRange && isClothInRange && isChoeInRange && isNewQuest;
    });

    console.log(sizeCloth);

    filtered = shuffleArray(filtered);

    setFilteredProducts(filtered);
    setCurrentPage(1);
};


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Обновляем текущую страницу
    window.scrollTo({ top: 1, behavior: 'smooth' });
  };

  const renderMetroStations = (metroIds) => {
    if (!metroIds || metroIds.length === 0) return null;

    let selectedStation = null;
    let remainingStationsCount = 0;

    if (selectedMetroStation) {
      selectedStation = metroStations.find(station => station.id === parseInt(selectedMetroStation, 10));
      remainingStationsCount = metroIds.length > 1 ? metroIds.length - 1 : 0;
    } else {
      selectedStation = metroStations.find(station => metroIds.includes(station.id));
      remainingStationsCount = metroIds.length - 1;
    }

    return (
      <div>
        <span>{selectedStation ? selectedStation.name : ''}</span>
        {remainingStationsCount > 0 && (
          <span> (+{remainingStationsCount})</span>
        )}
      </div>
    );
  };

  const renderAreas = (areaIds) => {
    if (!areaIds || areaIds.length === 0) return null;

    let selectedAreaObj = null;
    let remainingAreasCount = 0;

    if (selectedArea) {
      selectedAreaObj = areas.find(area => area.id === parseInt(selectedArea, 10));
      remainingAreasCount = areaIds.length > 1 ? areaIds.length - 1 : 0;
    } else {
      selectedAreaObj = areas.find(area => areaIds.includes(area.id));
      remainingAreasCount = areaIds.length - 1;
    }

    return (
      <div>
        <span>{selectedAreaObj ? selectedAreaObj.name : ''}</span>
        {remainingAreasCount > 0 && (
          <span> (+{remainingAreasCount})</span>
        )}
      </div>
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="container-gl">
      {loading ? (
        <div className="loading-container">
          <Hearts color="#FF0000" height={150} width={150} />
        </div>
      ) : isBlocked ? (
        <div className="blocked-container">
          <p>Извините, вы заблокированы за грубое нарушение правил сообщества! Решение обжалованию не подлежит!</p>
        </div>
      )  : isNoModels ? ( 
        <div className="blocked-container">
        <CustomHomeSidebar cityId={cityId} />
          <p>К сожалению сейчас в Вашем городе нет анкет, но они обязательно скоро появятся.</p>
        </div>
      ) : (
        <>
        <CustomHomeSidebar cityId={cityId} />
          <Filter onFilter={handleFilter} metroStations={metroStations} areas={areas} />
          <Row className="product-list-row">
            {currentItems.map(product => (
              <Col md={6} key={product.id}>
                <Card className="mb-2 product-card">
                    <Carousel 
                        className="product-carousel no-context-menu" 
                        controls={true}
                      >
                      {product.images && product.images.length > 0 ? (
                        product.images.map((photoUrl, index) => (
                          <Carousel.Item key={index}>
                            <div className="carousel-image-container">
                              <img
                                className="carousel-image"
                                src={photoUrl}
                                alt={`Slide ${index + 1}`}
                              />
                            </div>
                          </Carousel.Item>
                        ))
                      ) : (
                        <Carousel.Item>
                          <div className="carousel-image-container">
                            <img
                              className="carousel-image"
                              src="https://via.placeholder.com/300"
                              alt="No Image"
                            />
                          </div>
                        </Carousel.Item>
                      )}
                    </Carousel>

                  <Card.Body>
                  {product.newQuest === 1 ? (
                  <Badge.Ribbon text="Новая анкета" color="yellow">
                    <Card.Title className="productHome-name" onClick={() => handleProductClick(product.id)}>
                      <Theme preset={presetGpnDefault}>
                        {product.status === 1 ? (
                          <User className="no-context-menu" avatarUrl={product.images[0]} name={product.name} info="онлайн" status="available" size='l' width='full' />
                        ) : (
                          <User className="no-context-menu" avatarUrl={product.images[0]} name={product.name} info="офлайн" status="out" size='l' width='full' />
                        )}
                      </Theme>

                    </Card.Title> 
                    </Badge.Ribbon>
                    ) : (
                        <Card.Title className="productHome-name" onClick={() => handleProductClick(product.id)}>
                            <Theme preset={presetGpnDefault}>
                              {product.status === 1 ? (
                                <User className="no-context-menu" avatarUrl={product.images[0]} name={product.name} info="онлайн" status="available" size='l' width='full' />
                              ) : (
                                <User className="no-context-menu" avatarUrl={product.images[0]} name={product.name} info="офлайн" status="out" size='l' width='full' />
                              )}
                            </Theme>

                          </Card.Title> 
                    )}

                    <div className="productHome-soc">
                    <div className="product-divider"></div>
                      <MdVerified
                        onClick={(e) => {
                          e.preventDefault();
                          handleExplanationClick("Эта анкета официально подтверждена!");
                        }}
                        style={{ marginLeft: '2px', cursor: 'pointer' }}
                      />
                      {product.tgname && product.whatsapp && (
                        <>
                          <FaTelegramPlane style={{ marginLeft: '5px', cursor: 'pointer' }} />
                          <IoLogoWhatsapp style={{ marginLeft: '5px', cursor: 'pointer' }} />
                        </>
                      )}
                      {product.tgname && !product.whatsapp && (
                        <FaTelegramPlane style={{ marginLeft: '5px', cursor: 'pointer' }} />
                      )}
                      {product.whatsapp && !product.tgname && (
                        <IoLogoWhatsapp style={{ marginLeft: '5px', cursor: 'pointer' }} />
                      )}
                    </div>
                    {product.metro && product.metro.length > 0 && (
                      <div className="product-info-item">
                        <SiMoscowmetro className="product-info-icon" />
                        <strong>Станция метро:</strong>&nbsp;
                        {renderMetroStations(product.metro)}
                      </div>
                    )}
                    {product.area && product.area.length > 0 && (
                      <div className="product-info-item">
                        <PiMapPinAreaDuotone className="product-info-icon" />
                        <strong>Район:</strong>&nbsp;
                        {renderAreas(product.area)}
                      </div>
                    )}
                    <div className="product-info-item">
                      <FcBusinesswoman className="product-info-icon" />
                      <span className="product-info-text"><strong>Возраст:</strong> {isNaN(product.age) ? 'N/A' : `${product.age}`}</span>
                      <IoScaleSharp className="product-info-icon" />
                      <span className="product-info-text"><strong>Вес:</strong> {isNaN(product.weight) ? 'N/A' : `${product.weight}`}</span>
                    </div>
                    <div className="product-info-item">
                      <GiBodyHeight className="product-info-icon" />
                      <span className="product-info-text"><strong>Рост:</strong> {isNaN(product.height) ? 'N/A' : `${product.height}`}</span>
                      <IoResizeOutline className="product-info-icon" />
                      <span className="product-info-text"><strong>Грудь:</strong> {isNaN(product.gr_size) ? 'N/A' : `${product.gr_size}`}</span>
                    </div>
                    <div className="product-diider">
                      <Table className="white-table" dataSource={[{
                          key: '1',
                          price: product.price,
                          price2: product.price2,
                          price3: product.price3,
                        }]} 
                        columns={[
                          {
                            title: 'Час',
                            dataIndex: 'price',
                            key: 'price',
                          },
                          {
                            title: '2 часа',
                            dataIndex: 'price2',
                            key: 'price2',
                          },
                          {
                            title: 'Ночь',
                            dataIndex: 'price3',
                            key: 'price3',
                          },
                        ]}
                        pagination={false}
                      />
                    </div>
                    <div class="button-container">
                      <button class="more-info-button" onClick={() => handleProductClick(product.id)}>Подробнее</button>
                    </div>
                  </Card.Body>

                </Card>
              </Col>
            ))}
          </Row>
          <ExplanationModal
            show={showExplanation}
            explanation={explanationText}
            handleClose={handleCloseExplanation}
          />
                <div className="pagination-container">
        <Pagination
          current={currentPage}
          total={filteredProducts.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      </div>
        </>
      )}
      <Footer />
    </div>

  );
};

export default Home;
