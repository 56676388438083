import React from 'react';
import { BiRename } from 'react-icons/bi';
import { FaPassport } from 'react-icons/fa';
import { MdVerified } from "react-icons/md";
import { GiBodyHeight } from "react-icons/gi";
import { SiMoscowmetro } from "react-icons/si";
import { FcBusinesswoman } from "react-icons/fc";
import { IoScaleSharp } from "react-icons/io5";
import { PiMapPinAreaDuotone } from "react-icons/pi";
import { IoResizeOutline } from "react-icons/io5";
import ru_sizes_women from './constants/sizeClothWomen';
import shoeSizesRU from './constants/choeSizes';
import { GiSkirt, GiFemaleLegs } from "react-icons/gi";


const ProductInfo = ({ product, handleExplanationClick }) => (
  <div className="product-info-container">
    <div className="product-info-item">
      <BiRename className="product-info-icon" />
      <span className="product-info-text">
        <strong>Имя:</strong> {product.name}
        <MdVerified onClick={() => handleExplanationClick("Эта анкета официально подтверждена!")} style={{ marginLeft: '2px', cursor: 'pointer' }} />
      </span>
    </div>
    <div className="product-info-item">
      <FcBusinesswoman className="product-info-icon" />
      <span className="product-info-text"><strong>Возраст:</strong> {isNaN(product.age) ? 'N/A' : `${product.age}`}</span>
      <IoResizeOutline className="product-info-icon" />
      <span className="product-info-text"><strong>Грудь:</strong> {isNaN(product.gr_size) ? 'N/A' : `${product.gr_size}`}</span>
    </div>
    <div className="product-info-item">
      <GiBodyHeight className="product-info-icon" />
      <span className="product-info-text"><strong>Рост:</strong> {isNaN(product.height) ? 'N/A' : `${product.height}`}</span>
      <IoScaleSharp className="product-info-icon" />
      <span className="product-info-text"><strong>Вес:</strong> {isNaN(product.weight) ? 'N/A' : `${product.weight}`}</span>
    </div>
    <div className="product-info-item">
      <GiSkirt className="product-info-icon" />
      <span className="product-info-text">
        <strong>Размер одежды:</strong> 
        {isNaN(product.cloth) || product.cloth >= ru_sizes_women.length ? 'N/A' : ` ${ru_sizes_women[product.cloth]}`}
      </span>
    </div>
    <div className="product-info-item">
    <GiFemaleLegs className="product-info-icon" />
          <span className="product-info-text">
        <strong>Размер обуви:</strong> 
        {isNaN(product.choe) || product.choe >= shoeSizesRU.length ? 'N/A' : ` ${shoeSizesRU[product.choe]}`}
      </span>
    </div>

    {product.metroStations && product.metroStations.length > 0 && (
      <div className="product-info-item">
        <div className="metro-tags">
          {product.metroStations.split(', ').map((station, index) => (
            <span key={index} className="metro-tag">
              <SiMoscowmetro className="si-moscowmetro" /> {station}
            </span>
          ))}
        </div>
      </div>
    )}
    {product.areaNames && product.areaNames.length > 0 && (
      <div className="product-info-item">
        <div className="metro-tags">
          {product.areaNames.split(', ').map((area, index) => (
            <span key={index} className="metro-tag">
              <PiMapPinAreaDuotone className="si-moscowmetro" /> {area}
            </span>
          ))}
        </div>
      </div>
    )}
  </div>
);

export default ProductInfo;
