import React, { useState, useEffect } from 'react';
import Sidebar from 'react-sidebar';
import { FaHome } from 'react-icons/fa';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';
import { Select, MenuItem, FormControl, InputLabel, IconButton } from '@mui/material';
import { IoSaveOutline } from "react-icons/io5";
import './SidebarHome.css'; // Импорт стилей
import { postMultipartRequest } from '../Utils/api';
import { Link } from 'react-router-dom';

const CustomHomeSidebar = ({ cityId }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCity, setSelectedCity] = useState(cityId || ''); // Инициализируем состоянием cityId
  const [showCheckIcon, setShowCheckIcon] = useState(false); // Состояние для показа иконки галочки
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const tg = window.Telegram.WebApp;
  const tgId = tg.initDataUnsafe.user.id;

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  const setCity = async (city) => {
    try {
      const response = await postMultipartRequest('setCity', { tg_id: tgId, city: city });

        window.location.reload(); // Перезагрузка страницы при успешном ответе
    } catch (error) {
      console.error('Ошибка', error);
    }
  };

  useEffect(() => {
    document.body.style.overflow = sidebarOpen ? 'hidden' : 'auto';

    if (sidebarOpen) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${BASE_URL}/getCitysName`);
          const data = await response.json();
          setData(data); // Сохраняем данные в состояние
          console.log(cityId); // Логируем cityId для отладки
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
        }
      };

      fetchData(); // Вызов функции загрузки данных при открытии сайдбара
    }
  }, [sidebarOpen, BASE_URL, cityId]); // Добавляем cityId в зависимости

  const handleCityChange = (event) => {
    const newCity = event.target.value;
    setSelectedCity(newCity);
    setShowCheckIcon(newCity !== cityId); // Показываем иконку только если выбран другой город
  };

  const handleChangeCityClick = () => {
    setCity(selectedCity); // Вызываем setCity с выбранным городом
  };

  return (
    <div>
      <Sidebar
        sidebar={
          <div className="home-sidebar-content">
            <div className="home-header">
              <img src="https://graansen.com/public/modelImages/watermark.png" alt="Logo" className="home-logo" />
              <button className="home-toggle-btn" onClick={() => onSetSidebarOpen(false)}>
                <GoSidebarExpand />
              </button>
            </div>
            <div className="home-menu">
                            <Link to="/" className="no-select no-drag">
                <FaHome /> Главная
              </Link>
              <div className="home-dropdown" style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl fullWidth>
                  <InputLabel id="city-select-label">Выберите город</InputLabel>
                  <Select
                    labelId="city-select-label"
                    id="city-select"
                    value={selectedCity}
                    label="Выберите город"
                    onChange={handleCityChange}
                  >
                    {data.map(city => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name} - {city.model_count}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {showCheckIcon && (
                  <IconButton
                    color="primary"
                    onClick={handleChangeCityClick}
                    style={{ marginLeft: '10px' }}
                  >
                    <IoSaveOutline />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        }
        open={sidebarOpen}
        onSetOpen={onSetSidebarOpen}
        styles={{ sidebar: { background: "#ffffff", width: 250 }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.3)" } }}
      >
        <button className="home-open-btn" onClick={() => onSetSidebarOpen(true)}>
          <GoSidebarCollapse />
        </button>
      </Sidebar>
    </div>
  );
};

export default CustomHomeSidebar;
